<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>Users</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Reset Password</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="users-add">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>

          <div class="card-header">
            <h3 class="card-title">Reset Password</h3>
          </div>

          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group required col-md-12">
                  <label for="users" class="control-label">Select User</label>
                  <multiselect id="user_id" name="user_id" :options="Object.keys(users)"
                               :custom-label="opt => users[opt]"
                               v-model="inputs.user_id" class="user_id"
                               placeholder="Select Employee" :searchable="true"
                               :close-on-select="true" :show-labels="false"
                               @search-change="getAllUsers"
                               v-bind:class="{'has-error' : errors.has('user')}"
                               v-validate="'required'">
                  </multiselect>

                  <div class="help text-danger" v-show="errors.has('user')">
                    {{ errors.first('user') }}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group required col-md-12">
                  <label for="password" class="control-label">Password</label>
                  <input autocomplete="off" type="password" id="password" name="password" v-model="inputs.password"
                         v-validate="'required'" :class="{'has-error' : errors.has('password')}"
                         class="form-control" placeholder="new password">
                  <div class="help text-danger" v-show="errors.has('password')">
                    {{ errors.first('password') }}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group required col-md-12">
                  <label for="password_confirmation" class="control-label">Confirm Password</label>
                  <input autocomplete="off" type="password" id="password_confirmation" name="password_confirmation"
                         v-model="inputs.password_confirmation"
                         v-validate="'required'" :class="{'has-error' : errors.has('password_confirmation')}"
                         class="form-control" placeholder="confirm password">
                  <div class="help text-danger" v-show="errors.has('password_confirmation')">
                    {{ errors.first('password_confirmation') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-site">Reset Password</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {AuthAPI, CommonAPI} from "@/services/api";

export default {
  name: 'UserPasswordReset',
  metaInfo: {
    title: 'Reset Password | Dotlines'
  },
  components: {
    //
  },
  data: () => ({
    is_loading: false,
    users: [],
    inputs: {},
    isSelectorLoading: false,
  }),
  mounted: function () {
    this.getAllUsers();
  },
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          AuthAPI.resetUserPasswordByAdmin(this.inputs).then(response => {
            this.$toaster.success(response.message)
          }).catch(error => {
            this.$setErrorsFromResponse(error.data);
          }).finally(() => {
            this.inputs = {};
            this.is_loading = false;
          });
        }
      })
    },
    async getAllUsers(query = null) {
      this.is_loading = true;
      await CommonAPI.getAllUsers(query).then(users => {
        console.log('users', users)
        this.users = users;
      });
      this.is_loading = false;
    },
  }
}
</script>
